var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',{staticClass:"bg-body-color main"},[_c('div',{staticClass:"wrapper__auth-new",staticStyle:{"justify-content":"flex-start"}},[_c('div',{staticClass:"auth__logo",on:{"click":_vm.redirectToGeorApp}},[_c('img',{attrs:{"src":require("@/assets/images/logo.svg"),"alt":"Geor, iniciar sesión"}})]),_c('div',{staticClass:"auth__box"},[_c('span',{staticClass:"title__auth"},[_vm._v("Iniciar sesión")]),_c('p',{staticClass:"auth__box-p"},[_vm._v("Ingresa tu correo electrónico para acceder a tu cuenta.")]),_c('ValidationObserver',{ref:"formLoginEMailPassword",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[(_vm.form.type === 'EMAIL')?_c('ValidationProvider',{attrs:{"name":"correo electrónico","vid":"email","rules":"required|email|max:190"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Correo electrónico","error-messages":errors,"type":"email","name":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}):_vm._e(),(_vm.form.type === 'EMAIL')?_c('ValidationProvider',{attrs:{"name":"contraseña","vid":"password","rules":"required|min:6|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"name":"password","label":"Contraseña","error-messages":errors,"type":_vm.showPassword ? 'text' : 'password'},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.showPassword)?_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/icon-eye.svg"),"alt":"Ojo abierto"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/icon-eye-close.svg"),"alt":"Ojo cerrado"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}})]},proxy:true}],null,true),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}):_vm._e(),(_vm.form.type === 'PHONE')?_c('ValidationProvider',{attrs:{"name":"país","vid":"country_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('geor-select-countries',{attrs:{"countries":_vm.countries,"errors":errors},on:{"onChangeCountry":_vm.onCaptureCountry},model:{value:(_vm.form.country_id),callback:function ($$v) {_vm.$set(_vm.form, "country_id", $$v)},expression:"form.country_id"}})]}}],null,true)}):_vm._e(),(_vm.form.type === 'PHONE')?_c('ValidationProvider',{attrs:{"name":"teléfono","vid":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('############'),expression:"'############'"}],attrs:{"label":"Número de teléfono","type":"tel","prefix":_vm.form.prefix,"error-messages":errors,"name":"phone"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})]}}],null,true)}):_vm._e(),_c('div',{staticClass:"text-left"},[_c('router-link',{staticClass:"text-decoration-underline",staticStyle:{"text-decoration":"none!important"},attrs:{"to":{ name: 'ForgotPassword' }}},[_c('span',{staticClass:"forget-password"},[_vm._v("¿Olvidaste tu contraseña?")])])],1),_c('br'),_c('v-btn',{staticClass:"v-btn-pad-x-large btn-new",staticStyle:{"border-radius":"5px !important"},attrs:{"disabled":invalid || _vm.loading,"color":"primary","depressed":"","type":"submit","loading":_vm.loading,"block":""}},[_c('span',{staticClass:"text-button-new"},[_vm._v(" Continuar ")])])],1)]}}])})],1),_c('span',{staticStyle:{"font-size":"14px","margin-bottom":"32px"}},[_vm._v(" ¿No tiene una cuenta? "),_c('router-link',{staticStyle:{"text-decoration":"underline"},attrs:{"disabled":_vm.loading,"to":{ name: 'RegisterEmail' }}},[_c('small',{staticStyle:{"font-size":"14px !important"}},[_vm._v("Regístrate")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }