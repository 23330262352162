<template>
  <v-main class="bg-body-color main">
    <div style="justify-content: flex-start;" class="wrapper__auth-new">
      <div class="auth__logo" @click="redirectToGeorApp">
        <img src="@/assets/images/logo.svg" alt="Geor, iniciar sesión" />
      </div>
      <div class="auth__box">
        <span class="title__auth">Iniciar sesión</span>
        <p class="auth__box-p">Ingresa tu correo electrónico para acceder a tu cuenta.</p>
        <ValidationObserver v-slot="{ invalid }" tag="div" ref="formLoginEMailPassword">
          <form @submit.prevent="onSubmit">
            <!-- <ValidationProvider name="tipo" vid="type" rules="required" v-slot="{ errors }">
              <v-select
                v-model="form.type"
                :error-messages="errors"
                label="Iniciar sesión con mi"
                :items="types"
                item-value="id"
                item-text="text"
              ></v-select>
            </ValidationProvider> -->
            <ValidationProvider
              v-if="form.type === 'EMAIL'"
              name="correo electrónico"
              vid="email"
              rules="required|email|max:190"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.email"
                label="Correo electrónico"
                :error-messages="errors"
                type="email"
                name="email"
              ></v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-if="form.type === 'EMAIL'"
              name="contraseña"
              vid="password"
              rules="required|min:6|max:50"
              v-slot="{ errors }"
            >
              <v-text-field v-model="form.password" name="password"
                label="Contraseña" :error-messages="errors" :type="showPassword ? 'text' : 'password'">
                  <template v-slot:append>
                      <img v-if="showPassword" src="@/assets/images/icon-eye.svg" alt="Ojo abierto" @click="showPassword = !showPassword" style="cursor: pointer;">
                      <img v-else src="@/assets/images/icon-eye-close.svg" alt="Ojo cerrado" @click="showPassword = !showPassword" style="cursor: pointer;">
                  </template>
                </v-text-field>
            </ValidationProvider>
            <ValidationProvider
              v-if="form.type === 'PHONE'"
              name="país"
              vid="country_id"
              rules="required"
              v-slot="{ errors }"
            >
              <geor-select-countries
                :countries="countries"
                :errors="errors"
                v-model="form.country_id"
                @onChangeCountry="onCaptureCountry"
              ></geor-select-countries>
            </ValidationProvider>
            <ValidationProvider
              v-if="form.type === 'PHONE'"
              name="teléfono"
              vid="phone"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.phone"
                label="Número de teléfono"
                type="tel"
                :prefix="form.prefix"
                :error-messages="errors"
                v-mask="'############'"
                name="phone"
              ></v-text-field>
            </ValidationProvider>
           <div class="text-left">
              <router-link style="text-decoration: none!important;"
                class="text-decoration-underline"
                 :to="{ name: 'ForgotPassword' }"
                ><span class="forget-password"  >¿Olvidaste tu contraseña?</span>
              </router-link>
            </div>
            <br />
            <v-btn
              :disabled="invalid || loading"
              color="primary"
              depressed
              type="submit"
              :loading="loading"
              block
              class="v-btn-pad-x-large btn-new"
              style="border-radius: 5px !important;"
              ><span class="text-button-new" >
                Continuar
              </span></v-btn
            >
          </form>
        </ValidationObserver>
      </div>
      <!-- <div @click="redirectToQR" class="button-login-outline">
        <router-link :to="{ name: '' }" >
            <small  style="font-size: 14px !important;">INGRESAR CON CÓDIGO QR</small>
        </router-link>
      </div>
      <router-link :to="{ name: 'Home' }" :disabled="loading" class="button-login-outline">
          <small style="font-size: 14px !important;">INGRESAR CON CELULAR</small>
      </router-link> -->
      <span style="font-size: 14px; margin-bottom: 32px;">
        ¿No tiene una cuenta? <router-link
        :disabled="loading" style="text-decoration: underline;"
        :to="{ name: 'RegisterEmail' }"
        >
          <small style="font-size: 14px !important;">Regístrate</small>
      </router-link></span>
    </div>
  </v-main>
</template>

<script>
import { mapState } from "vuex";
import SelectCountries from "@/components/partials/SelectCountries.vue";
// import BtnWhatsapp from "../components/BtnWhatsapp.vue";

export default {
  components: {
    // BtnWhatsapp,
    "geor-select-countries": SelectCountries,
  },
  data() {
    return {
      showPassword: false,
      baseUrl: process.env.VUE_APP_URL_WEB,
      form: {
        email: "",
        password: "",
        type: "EMAIL",
        country_id: "PE",
        prefix: "+51",
        phone: "",
      },
      types: [
        { id: "EMAIL", text: "Correo electrónico" },
        { id: "PHONE", text: "Teléfono" },
      ],
    };
  },
  mounted() {
    if (this.countries.length === 0) {
      this.$store.dispatch("LOAD_COUNTRIES");
    }
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      countries: (state) => state.countries,
    }),
  },
  methods: {
    onSubmit() {
      this.$axios
        .post("auth/login", this.form)
        .then((response) => {
          if (this.form.type === "EMAIL") {
            const { data } = response;
            const token = this.$auth.generateToken(data.data.token_type, data.data.access_token);
            this.$auth.saveUser(data.data.user);
            this.$store.dispatch("ON_LOGIN", token);
            this.$router.push({ name: "Dashboard" });
          } else {
            this.$router.push({
              name: "VerifyLoginCode",
              query: {
                phone: this.form.phone,
                country_id: this.form.country_id,
                prefix: this.form.prefix,
              },
            });
          }
        })
        .catch((error) => this.showErrors(error, "formLoginEMailPassword"));
    },
    onCaptureCountry(countryId) {
      const country = this.countries.find((c) => c.id === countryId);
      this.form.prefix = country.prefix;
    },
    redirectToGeorApp() {
      // Redirecciona a la ruta externa
      window.location.href = 'https://geor.app/';
    },
    redirectToQR() {
      // Redirecciona a la ruta externa
      window.location.href = 'https://geor.app/qr';
    },
  },
};
</script>
